<template lang='pug'>
	div
		.d-flex.position-relative.my-input(:class="[{'is-focused': isFocused, 'is-float': value && !isFocused, 'is-error': errorFeedback != '' || isError, 'no-icon': icon == '', 'blocked' : disabled}, icon, classInput]")
			input.b3.w-100.my-input__input(
				autocomplete='off'
				@input="$emit('input', $event.target.value)"
				:value="value"
				:type="type"
				:id="`input-${_uid}`"
				@focus="isFocused = true, $emit('focus')"
				@blur="isFocused = false, $emit('blur')"
				:maxlength="maxlength"
				:disabled='disabled'
				@keypress='onKeyPress'
				v-mask='mask'
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
			)

			label.m-0.b3.my-input__label(:class="{'b4': isFocused || value}") {{ label }}
			icon-close.my-input__reset(v-if="value && maxlength != '2' && !disabled" @click.prevent="type == 'tel' ? $emit('input', '+7 (') : $emit('input', ''), $emit('blur')")
			svg.cursor-pointer.my-input__reset-error(@click.prevent="$emit('input', '')" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none")
				path(d="M17.4168 9.4987C17.4168 5.14453 13.8543 1.58203 9.50016 1.58203C5.146 1.58203 1.5835 5.14453 1.5835 9.4987C1.5835 13.8529 5.146 17.4154 9.50016 17.4154C13.8543 17.4154 17.4168 13.8529 17.4168 9.4987Z" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				path(d="M7.25977 11.7386L11.7406 7.25781" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				path(d="M11.7406 11.7386L7.25977 7.25781" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
			span.b3.my-input__text(v-if="value && maxlength == '2'") %
		.text-danger.b4.mt-1(v-if="errorFeedback != ''" v-html='errorFeedback')
</template>

<script>
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'MyInput',
	components: {
		IconClose
	},
	props: {
		type: {
			type: String,
			default: 'text',
		},
		mask: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		errorFeedback: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		maxlength: {
			type: [String, Number],
			default: '10000',
		},
		prefix: {
			type: String,
			default: '',
		},
		value: {
			default: null,
		},
		classInput: {
			default: '',
		},
		isError: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		}
	},
	data: () => ({
		isFocused: false
	}),
	methods: {
		onKeyPress(event) {
			if ((event.key == 'e' || event.key == '+' || event.key == '-') && this.type == 'number') {
				event.preventDefault();
			}
		}
	},
	watch: {
		value () {
			if (!this.prefix) {
				return
			}
			const vLC = this.value.toLowerCase()
			const pLC = this.prefix.toLowerCase()
			if (!vLC.startsWith(pLC)) {
				let val = pLC.startsWith(vLC) ? '' : this.value
				this.value = this.prefix + val
			}
		},
	},
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.my-input {
	background-color: var(--grey-bg-color);
	border-radius: var(--border-radius-rounded);

	&::before {
		z-index: 1;
		position: absolute;
		content: '';
		top: 50%;
		left: 20px;
		background-color: var(--primary-text-color);
		mask-repeat: no-repeat;
		mask-size: cover;
		transform: translateY(-50%);
	}

	&::after {
		z-index: 1;
		position: absolute;
		content: none;
		top: 50%;
		right: 20px;
		background-size: cover;
		transform: translateY(-50%);
	}

	&.icon-lightning {
		&::before {
			width: 20px;
			height: 23px;
			mask-image: url('/assets/img/icons/lightning.svg');
		}
	}

	&.icon-usage {
		&::before {
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/usage.svg');
		}
	}

	&.icon-discount {
		&::before {
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/discount.svg');
		}
	}

	&.icon-money {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/money.svg');
		}
	}

	&.icon-sms {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/sms.svg');
		}
	}

	&.icon-search {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/search.svg');
		}
	}

	&.icon-link {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/link.svg');
		}
	}

	&.icon-archive-book {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/archive-book.svg');
		}
	}

	&.icon-home-hashtag {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/home-hashtag.svg');
		}
	}

	&.icon-wallet {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/wallet.svg');
		}
	}

	&.icon-card-tick {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/card-tick.svg');
		}
	}

	&.icon-profile {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/profile.svg');
		}
	}

	&.icon-call {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/call.svg');
		}
	}

	&.icon-document {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/document.svg');
		}
	}

	&.icon-house {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/house.svg');
		}
	}

	&.icon-terms-of-use {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/terms-of-use.svg');
		}
	}

	&.icon-bank {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/bank.svg');
		}
	}

	&.is-focused {
		background-color: var(--foreground-color);

		.my-input__input {
			padding: 24px 40px 6px 50px;
			color: var(--main-text-color);
		}

		.my-input__label {
			z-index: 1;
			top: 30%;
		}
	}

	&.is-float {
		background-color: var(--grey-bg-color);

		.my-input__input {
			padding: 24px 40px 6px 50px;
			color: var(--main-text-colorr);
		}

		.my-input__label {
			z-index: 1;
			top: 30%;
		}
	}

	&.is-error {
		background-color: transparent;

		.my-input__input {
			border: 1.5px solid var(--red-bg-color) !important;

			&:hover,
			&:focus {
				border: 1px solid var(--red-bg-color) !important;
			}
		}

		.my-input__label {
			color: var(--red-bg-color);
		}

		&::before {
			background-color: var(--red-text-color);
		}
	}

	&.is-error-icon {
		background-color: transparent;

		.my-input__input {
			border: 1.5px solid var(--red-bg-color) !important;

			&:hover,
			&:focus {
				border: 1px solid var(--red-bg-color) !important;
			}
		}

		.my-input__label {
			color: var(--red-bg-color);
		}

		.my-input__reset {
			display: none;
		}

		.my-input__reset-error {
			z-index: 1;
			position: absolute;
			top: 50%;
			right: 19px;
			display: block;
			width: 19px;
			height: 19px;
			transform: translateY(-50%);
			transition: var(--animation-time-short);

			&:hover,
			&:active {
				opacity: 0.7;
			}
		}
	}

	&.is-success-icon {
		background-color: transparent;

		.my-input__input {
			border: 1.5px solid var(--brand-success) !important;

			&:hover,
			&:focus {
				border: 1px solid var(--brand-success) !important;
			}
		}

		.my-input__label {
			color: var(--brand-success);
		}

		&::after {
			content: '';
			width: 19px;
			height: 19px;
			mask-image: url('/assets/img/icons/tick-circle.svg');
			background-color: var(--brand-success);
		}

		&::before {
			background-color: var(--brand-success);
		}

		.my-input__reset {
			display: none;
		}
	}

	&__input {
		z-index: 1;
		align-items: center;
		height: 52px;
		padding: 15px 40px 15px 50px;
		border: 1.5px solid transparent;
		background-color: transparent;
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);
		cursor: text;

		&:hover {
			background-color: transparent !important;
			border: 1.5px solid var(--button-border-color) !important;
		}

		&:focus {
			background-color: transparent !important;
			border: 1.5px solid var(--button-border-color) !important;
		}
	}

	&__label {
		position: absolute;
		top: 50%;
		left: 50px;
		color: var(--grey-text-color);
		transform: translateY(-50%);
		cursor: text;
		transition: var(--animation-time-short);
	}

	&__reset {
		z-index: 1;
		position: absolute;
		top: 50%;
		right: 19px;
		transform: translateY(-50%);
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}

	&__reset-error {
		display: none;
	}

	&__text {
		position: absolute;
		bottom: 6px;
		left: 70px;
	}

	&.no-icon {
		.my-input__input {
			padding: 24px 40px 6px 24px;
		}

		.my-input__label {
			left: 24px;
		}

		&.is-focused {
			.my-input__input {
				padding: 24px 40px 6px 24px;
			}
		}

		&.is-float {
			.my-input__input {
				padding: 24px 40px 6px 24px;
			}
		}
	}

	&.change-bot__input-name {
		.my-input__label {
			left: 25px;
		}
	}

	&.change-bot__input-token.is-error {
		.my-input__label {
			color: #FF7C7C;
		}

		.my-input__input {
			border-color: #FF7C7C !important;

			&:hover, &:focus {
				border-color: #FF7C7C !important;
			}
		}
	}

	&.change-bot__input-name, &.change-bot__input-token {
		background-color: var(--bg-back);

		&.is-focused, &.is-error {
			background-color: var(--foreground-color) !important;
		}
	}
}
</style>
